import { Component, Vue } from "vue-property-decorator";

import { STHeader, STFooter } from "@/layout";
import { APAudio } from "@/components";
import { HomeworkModel } from "@/models";
import { StCommon } from "../Common";
import { ErrorMessage } from "@/constant";

type response = { [key: string]: unknown };

@Component<ApStudentHomeworkIndexController>({
    components: {
        STHeader, APAudio, STFooter
    }
})
export default class ApStudentHomeworkIndexController extends Vue {
    private doubleClick = false;
    private paperList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private stid = window.localStorage.getItem('student_stid') as string;
    private token = window.localStorage.getItem('studentToken') as string;

    private audioLB = {
        show: false,
        errorMsg: '',
    }
    private audio = '';

    public created() {
        this.getPaperList();
    }

    private async getPaperList() {
        const item = {
            page: this.page,
            perpage: this.perpage,
            stid: this.stid,
            token: this.token,
        }
        const data = await HomeworkModel.stGetPaperList(item) as response
        StCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.paperList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }

    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getPaperList();
    }

    private openAudioLightBox() {
        this.audioLB.show = true;
    }

    private async uploadAudio(data: FormData) {

        if (!this.doubleClick) {
            this.doubleClick = true;

            data.append('stid', this.stid);
            data.append('token', this.token);

            const errNo = await HomeworkModel.stUploadAudio(data);
            // this.audioLB.show = false;
            StCommon.checkToken(errNo);

            this.audioLB.errorMsg = ErrorMessage[errNo];
            this.doubleClick = false;
        }
    }

    private closeAudioLightBox() {
        this.audioLB.show = false;
        this.$router.go(0);
    }

}